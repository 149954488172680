.icon-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 50px;
  height: 50px;
  left: 278px;
  top: 0px;

  background: #f6f6f6;
  border-radius: 8px;

  margin: 0px 3px;
}
