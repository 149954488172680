/* @media all and (max-width: 1079px) { */
@media all and (max-width: 639px) {
  .mobile_gray_backgound {
    background-color: white;
    min-width: 350px;
  }
  .main_container_wrapper {
    /* display: flex; */
    /* position: absolute;
    width: 100vw; */
    align-items: center;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    width: calc(100% - 0.875rem * 2);

    height: 100%;
    /* position: relative; */
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
  }

  .main_container {
    width: 100%;
    padding-top: 32px;
  }

  .web_only {
    display: none;
  }

  .full_width {
    /* width: 100vw !important;
    margin-left: calc(-50vw + 50%); */
  }

  .full_height {
    height: calc(100vh - 60px) !important;
    min-height: 600px;
    margin-top: calc(-50vw + 50%);
  }

  .full_height_without_gnb {
    height: calc(100vh - 60px) !important;
    /* margin-top: calc(-50vw + 50%); */
  }

  /* ===[MAIN]================================================================================= */
  .main_section {
    width: 100%;
    min-height: 730px;
    padding-top: 80px;
    /* height: 600px; */
  }

  .home_background_image {
    /* margin-top: 60px; */
    background-size: cover;
    background-image: url('/public/image/mobileBackGround.png');
    /* width: 100%; */
  }

  .main_left {
    min-width: 375px;
  }
  .main_rigth {
    min-width: 375px;
  }

  .main_title_container {
    text-align: center;
    /* height: fit-content; */
    /* padding-top: 24vh; */
    /* padding-top: 24%; */
  }

  .main_one_click {
    padding-top: -40px;
    /* padding-right: 120px;
    padding-top: 280px; */
  }

  .main_title_sub {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .main_phone_img {
    /* padding-top: 8vh; */
    height: 431px;
    text-align: center;
    z-index: 10;
  }

  .main_phone_img_top {
    padding-top: 24px;
  }

  .main_overlap_img_sns_1 {
    height: 61px;
    position: absolute;
    left: 32px;
    top: 586px;
    z-index: 0;
  }
  .main_overlap_img_sns_2 {
    position: absolute;
    height: 63px;

    left: -12px;
    top: 240px;
  }
  .main_overlap_img_sns_3 {
    position: absolute;
    height: 75px;

    right: 30px;
    top: 354px;
  }
  .main_overlap_img_sns_4 {
    position: absolute;
    height: 66px;
    right: -16px;
    top: 514px;
    overflow: hidden;
    max-width: 50px;
  }

  .main_overlap_img_phone {
    position: absolute;
    padding-top: 240px;
    height: 432px;
    padding-left: calc(50% - 109px);
  }
  .main_overlap_img_phone3 {
    position: absolute;
    padding-top: 40px;
    height: 432px;
    padding-left: calc(50% - 109px);
  }
  .main_overlap_img_icons {
    position: absolute;
    padding-top: 186px;
    /* left: 0px; */
    height: 360px;
    max-width: 100vw;
    overflow: hidden;
  }

  .phone3 {
    height: 432px;
  }

  .main_overlap_img_map {
    position: absolute;
    height: 140px;
    right: -16px;
    top: 8px;
    z-index: -1;
  }
  .main_overlap_img_call {
    position: absolute;
    height: 134px;
    /* width: 134px; */
    left: -16px;
    padding-top: 304px;
    z-index: -1;
  }

  .main_title_influencer {
    font-size: 60px;
    color: white;
    font-weight: 100;
    width: 100%;
    padding-top: 40px;
  }

  .main_title_influencer_sub {
    padding-top: 16px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.599);
  }

  .main_title_influencer_container {
    padding-top: 64px;
    display: flex;
    width: 100vw;
    overflow-x: scroll;
  }
  .main_title_influencer_img_container {
    object-fit: cover;
    object-position: center;
    width: 200px;
    height: 200px;
    border-radius: 110px;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 16px;
  }
  .main_title_influencer_name {
    position: absolute;
    color: white;
    z-index: 2;
    /* margin-left: 60px; */
    text-align: center;
    width: 200px;
    font-size: 20px;
    margin-top: 72px;
  }
  .main_title_influencer_type {
    width: 100%;
    position: relative;
    text-align: center;
  }

  .main_title_influencer_type_text {
    position: absolute;
    margin-top: 124px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #ffffff99;

    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 40px;
  }

  .main_overlap_img_crown {
    position: absolute;
    /* margin-left: 106px; */
    left: calc(50%);
    /* margin-left: calc(50% + 6px); */
    margin-top: -25px;
  }
  .main_overlap_img_curve {
    position: absolute;
    margin-top: 166px;
    left: calc(50% - 84px);
  }

  .main_title_url {
    display: inline-block;
    width: 284px;
    height: 60px;
    line-height: 60px;
    border: 1px solid white;
    border-radius: 60px;
    text-align: left;
    margin-top: 80px;
    padding-left: 16px;
  }

  .main_title_url_logo_box {
    display: none;
  }

  .main_title_url_logo {
    height: 50px;
    /* position: absolute;
    padding-top: 15px; */
  }

  /* ===[FONT]================================================================================= */
  .font_title_eng {
    font-size: 50px;
  }
  .font_title_kor {
    font-size: 40px;
  }

  /* ===[GNB]================================================================================= */

  .gnb_container_wrapper {
    position: fixed;
    z-index: 1;
    background-color: white;

    margin-top: auto;
    height: 60px;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;

    min-width: 260px;

    top: 0;
    right: 0;
    left: 0;
  }

  .gnb_container_bg {
    /* background: rgba(255, 255, 255, 0.75);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 80px;
    transition: 0.5s;
    transition-timing-function: ease;
    opacity: 0;
    z-index: 100000;
    backdrop-filter: blur(10px); */
  }

  .gnb_container_bg.on {
    /* transition: 0.5s;
    transition-timing-function: ease;
    opacity: 1; */
  }
  .gnb_container {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 0.875rem * 2);
    /* height: 60px; */
    line-height: 60px;
  }

  .gnb_logo_container {
    padding-top: 16px;
  }

  .gnb_logo {
    height: 25px;
  }

  .gnb_more_menu {
    float: right;
    padding-top: 8px;
  }

  .gnb_menu {
    visibility: hidden;
    padding-left: 10px;
  }

  .gnb_drawer_menu_wrapper {
    width: 210px;
    padding: 16px;
  }
  .gnb_drawer_menu_container {
    padding-top: 60px;
    padding-left: 14px;
  }
  .gnb_drawer_menu {
    padding-top: 16px;
  }

  .gnb_clicked_menu {
    border-bottom: 2px solid gray;
    padding-bottom: 6px;
  }

  .gnb_more_menu_popper_wrapper {
    background-color: gray;
    width: 400px;
    height: 400px;
    border-radius: 8px;
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12); */
  }
  .fnb_wrapper {
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100000;
    text-align: center;
  }
  /* 
  .page {
    position: relative;
    top: 50px;
  } */
  .user-profile {
    height: 300px;
  }

  /* Section */
  .section-header {
    font-size: 20px;
    font-weight: 500;
    padding: 20px;
  }
  /* Section */
  /*
  .link-container-text {
    border-radius: 10px;
    text-align: center;
    word-wrap: break-word;
    min-height: 50px;
    padding: 5px;
    justify-content: space-between;
    display: flex;
  } */

  .no-margin-top {
    margin-top: 0;
  }

  /* ===[FOOTER]================================================================================= */
  .footer {
    height: 312px;
    background-color: #373737;
  }

  .footer_logo {
    height: 50px;
    padding-top: 60px;
  }

  .footer_des {
    padding-top: 32px;
    font-size: 12px;

    color: white;
    opacity: 0.7;
  }

  /* ===[signUp]================================================================================= */
  .signUp-title {
    margin-bottom: 1em;
  }

  .signUp-bottom {
    padding: 1em;
    background: #fbfbfb;
  }

  .signUp-bottom-section {
    margin-bottom: 1.5em;
  }

  .signUp-input {
    width: 95%;
    padding: 0.6em;
    height: 2em;
    /* border-radius: 20px; */
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: none;
    /* padding-left: 9em; */
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05); */
  }

  .input-container {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }

  .input-container-div {
    background: white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 0.65em 0 0.65em 1em;
    width: 100px;
  }

  .signUp-terms {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signUp-terms-box {
    margin: 2em 0;
    width: 90%;
  }

  .signUp-terms-box > p {
    margin: 0.4em;
    cursor: pointer;
  }

  .signUp-terms-more {
    color: #3e64ea;
    cursor: pointer;
    text-align: right;
    margin: 0.4em;
    margin-top: 0;
    width: 5em;
  }

  .signUp-terms-section1 {
    display: flex;
    align-items: center;
  }

  .signUp-terms-section1 input[type='checkbox'] {
    display: none;
  }

  .signUp-terms-section1 input[type='checkbox'] + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 17px;
    height: 17px;
    border-radius: 7px;
    border: 1px solid #dddddd;
    margin-right: 0.5em;
  }

  .signUp-terms-section1 input[type='checkbox']:checked + label {
    border: 1px solid #3e64ea;
    color: #3e64ea;
  }

  .signUp-terms-section1 input[type='checkbox']:checked + label::after {
    content: '✔';
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    width: 17px;
    height: 14px;
  }

  .signUp-terms-section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signUp-terms-section2 > p:first-child {
    font-size: 12px;
    color: #666666;
  }

  .signUp-btn-box {
    background: white;
    border-radius: 20px;
    padding: 1.5em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }

  .signUp-pageType-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .signUp-business {
    margin-top: 0.5em;
  }

  .signUp-button button {
    font-size: 16px;
    background: #3e64ea;
    color: white;
    box-shadow: none;
    border: none;
    overflow: visible;
    cursor: pointer;
    height: 4em;
    width: 100%;
  }

  #id-confirm-text,
  #email-confirm-text,
  #id-check-text,
  #email-check-text,
  #password-check-text,
  #terms-check-text {
    font-size: 14px;
    margin-top: 1.5em;
    display: block;
    color: #f92457;
    text-align: center;
  }

  #terms-check-text {
    margin-bottom: 0.5em;
  }

  .not-show-text {
    display: none !important;
  }

  .show-text {
    display: block !important;
    color: red;
    margin-top: 1em;
  }

  .blue-button {
    background-color: #3e64ea;
    margin-right: 10px;
    height: 50px;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 48px;
    cursor: pointer;
  }

  .join-btn-section {
    display: flex;
    justify-content: center;
    margin: 2em 0;
  }

  .join-btn {
    margin: 0;
    border-radius: 40px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
  }

  .signUp-id {
    margin-top: 1em;
  }

  .signUp-id label,
  .signUp-mail label,
  .signUp-password label {
    position: absolute;
    left: 2.3em;
  }

  /* ===[setting]================================================================================= */
  .setting-top {
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4em 1em;
  }

  .setting-bottom {
    padding: 1em;
  }

  .setting-bottom-section {
    margin-bottom: 1em;
  }

  .setting-title {
    margin-bottom: 1em;
    color: #666666;
    font-size: 14px;
  }

  .setting-info {
    padding: 0 1em;
    font-size: 12px;
  }

  /* ===[use-terms]================================================================================= */

  .clicked {
    color: #144da8;
  }

  /* ===[Edit Profile]================================================================================= */

  /* .margin-bottom-2 {
    margin-bottom: 2em;
  } */

  .dark-background-div {
    background: #323d45;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  /* .editUserInfo-container {
    padding: 0 1em;
  } */

  /* .dash-bar {
    content: "";
    border-bottom: 1px dashed #e5e5e5;
  } */

  /* .editUserInfo-container > section > p > span {
    font-weight: bold;
  } */

  /* .editUserInfo-container > section {
    margin-bottom: 2em;
    margin-top: 1em;
  } */

  /* .editUserInfo-container > section > p {
    margin-top: 0;
    font-size: 14px;
  } */

  /* .editUserInfo-container > section > input {
    border: 1px solid #dddddd;
    border-radius: 3px;
    padding: 1em;
    width: 94%;
  } */

  /* .sns-divideBar {
    background-color: #f6f6f6;
    height: 7em;
    object-fit: contain;
    display: flex;
    align-items: center;
  }

  .sns-divideBar > div {
    background-color: #dddddd;
    height: 5.5em;
    object-fit: contain;
    width: 1.5px;
  } */

  /* .editUserInfo-sns-title {
    padding-left: 1em;
    font-weight: bold;
    margin-top: 2em;
    font-size: 14px;
  } */

  /* .editUserInfo-sns-list{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 2em;
    width: 100%;
  }

  .editUserInfo-sns-url {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  } */

  /* .sns-list-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
    border: 1.5px solid #dddddd;
    background: white;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 1em;
    margin-right: 1em;
    color: #333;
  } */

  /* .editUserInfo-sns-url > p {
    margin-left: 1em;
    font-size: 14px;
    font-weight: bold;
  } */

  /* .editUserInfo-sns-url input, */
  .sns-url-btn {
    padding: 1em 1em;
    /* margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 2em; */
    margin: 0 1em 2em 1em;
    border-radius: 5px;
  }

  /* .editUserInfo-sns-url input {
    border: 1.5px solid #dddddd;
  } */

  .sns-url-btn {
    border: none;
    background: #d0dbf1;
    color: #333;
    margin-top: 2em;
    cursor: pointer;
  }

  .sns-list-menu {
    display: none;
  }

  .sns-list-menu-click {
    height: 100vh;
    position: absolute;
    /* background: white; */
    width: 100%;
    /* border-radius: 10px; */
    /* border-top-left-radius: 10px; */
    /* border-top-right-radius: 10px; */
    /* border-top: 1px solid #dddddd; */
    margin-bottom: 3em;
    z-index: 1000;
  }

  .sns-list-menu-click > div {
    /* width: 100%; */
    padding: 0 1em;
    padding-bottom: 1em;
    background: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .sns-list-menu-click > div > section {
    display: flex;
    justify-content: space-between;
  }

  .sns-list-menu-click > div > section > p {
    font-weight: bold;
  }

  .close-btn {
    background: white;
    border: none;
    cursor: pointer;
  }

  .user-profile {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }

  .header-component {
    height: 60px;
  }
}
