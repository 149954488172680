input {
  width: 30%;
  padding: 6px;
  border: 1px solid #cbcbcb;
  outline: none;
}

.container {
  height: 100%;
  background: white;
  display: flex;
}

.channel-type {
  padding: 12px;
  color: #6210cc;
}

.group-card {
  width: fit-content;
  padding: 15px;
  margin-top: 20px;
  border: 1px solid white;
  border-radius: 10px;
}

.card {
  width: fit-content;
  padding: 15px;
  margin-left: 60px;
  border: 1px solid black;
  border-radius: 10px;
}

.card-name {
  font-weight: bold;
}

.channel-list {
  background: white;
  flex: 1;
  border-right: 1px solid #cbcbcb;
  overflow-y: scroll;
}

.channel-list-item {
  background: #fcfcfc;
  padding: 12px;
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
}

.channel-list-item:hover {
  background: #f0f0f0;
}

.channel-list-item:hover .channel-icon {
  visibility: visible;
}

.sort-channels {
  width: 100%;
  font-size: 16px;
  padding: 7px;
  cursor: pointer;
}

.unarchive {
  transform: rotate(180deg);
}

.archived-channel {
  padding: 10px;
}

.last-message {
  font-weight: 300;
}

.channel-list-item-name {
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  max-width: 75ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.channel-list-item-sup {
  margin: 0 0 0 5px;
  font-size: 10px;
}

.channel-icon {
  visibility: hidden;
  width: 20px;
}

.channel {
  background: white;
  flex: 3;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
}

.channel-fixed-header {
  margin-top: 160px;
}

.thread {
  max-width: 35vw;
}

.thread,
.thread-input {
  border-left: 1px solid lightgray;
}

.underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.threads {
  max-width: 35vw;
}

.threads,
.threads-input {
  border-left: 1px solid lightgray;
}

.underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.channel-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leave-channel {
  margin-left: 12px;
  padding: 8px;
}

.freeze-channel {
  margin-right: 12px;
}

.freeze-channel input {
  width: auto;
}

.message-list {
  background: #f5f7fa;
  height: 100%;
  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: flex-end;
}

.message-item {
  margin: 12px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.message-box .date {
  font-size: 11px;
  color: #777;
  padding: 0 10px;
}
.message.message-from-you {
  margin-left: auto;
  background: #173300;
  color: #fff;
  flex-direction: row;
  margin-right: 12px;
  border-bottom-left-radius: 20px;

  border-bottom-right-radius: 5px;
}

.message-item.message-from-you {
  flex-direction: row;
}

.oc-message,
.oc-message-og-tags {
  padding: 12px;
  display: flex;
}

.oc-message-og-tags {
  flex-direction: column;
  max-width: 50%;
  padding: 5px;
  margin-left: 7vw;
  margin-top: -10px;
  box-sizing: border-box;
  border: 1px solid black;
}

.oc-message,
.message-og-tags {
  padding: 12px 0px;
  display: flex;
}

.message-og-tags {
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
}

.og-tags-title {
  margin: 0 0 10px;
}

.og-tags-description,
.og-tags-url,
.metadata-modal-list {
  margin: 0 0 5px;
}

.og-tags-img,
.show-users-status-btn,
.members-invite-btn {
  width: 100%;
}

.oc-admin-message {
  background: rgba(255, 0, 0, 0.05);
}

.report-notification {
  background: rgba(0, 255, 0, 0.5);
  color: white;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin: 5px 0;
  padding: 5px;
}

.message {
  border-radius: 20px;
  border-bottom-left-radius: 5px;
  width: auto;
  background: #fff;
  color: #383838;
  padding: 10px 20px;
  margin-left: 12px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}

.admin-message {
  background: rgba(255, 0, 0, 0.05);
  width: 100%;
  color: black;
  margin-left: 0;
}

.message-info {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  line-height: 24px;
}

.message-icon {
  visibility: hidden;
  width: 14px;
  filter: brightness(0) invert(1);
}

.oc-message-icon,
.oc-channel-list-icon {
  visibility: hidden;
  width: 14px;
}

.message:hover .message-icon,
.channel-list-item:hover .oc-channel-list-icon,
.member-item:hover .message-icon,
.double_tick-icon,
.member-item-wrapper .message-icon {
  visibility: visible;
}

.double_tick-icon-read {
  filter: none;
}

.mute-button {
  margin-top: 3px;
}

.member-item .message-icon {
  filter: none;
}

.oc-document-message,
.message-user-info,
.react-button-wrapper,
.reactions-list,
.metadata-modal-list,
.create-channel-wrapper {
  display: flex;
}

.reactions-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  background: white;
  border-radius: 8px;
}

.reactions-list li {
  margin-right: 2px;
  padding: 4px;
}

.react-button-wrapper {
  margin: 5px 5px 0 0;
  width: 100%;
  justify-content: flex-end;
}

.react-button-img {
  width: 20px;
  filter: none;
}

.reactions {
  margin: 5px 0 0;
}

.reactions-item {
  cursor: pointer;
  margin-right: 5px;
}

.reactions-item-inner {
  font-size: 10px;
}

.oc-document-message-icon {
  width: 24px;
}

.oc-message:hover .oc-message-icon,
.message-input .oc-message-icon,
.metadata-modal-list .oc-message-icon {
  visibility: visible;
}

.oc-message-sender-name {
  color: #6210cc;
  margin-left: 4px;
  font-weight: 600;
  margin-right: 4px;
}

.message-sender-name {
  font-weight: 600;
  margin-right: 4px;
}

.channel-header {
  padding: 12px;
  font-size: 24px;
  font-weight: 800;
}

.channel-header-wrapper {
  position: fixed;
  width: 100%;
  top: 0;
}

.members-list {
  border-left: 1px solid #cbcbcb;
  padding: 12px;
}

.member-item {
  padding: 8px 0px 0px 0px;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.member-item-wrapper {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.member-item:hover {
  background: #f0f0f0;
}

.member-item-name {
  margin-left: 12px;
}

.member-list-btn {
  margin: 0 0 5px;
}

.member-selected {
  color: #62eeab;
}

.operators-list {
  padding: 20px 0;
  font-weight: bold;
}

.operator-item {
  padding: 8px 0px 0px 0px;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.operator-item:hover {
  background: #f0f0f0;
}

.message-input {
  position: fixed;
  bottom: 0px;
  display: flex;
  border-top: 1px solid #cbcbcb;
  width: 100%;
  padding: 12px;
  background: white;
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #ecececb0;
  z-index: 1;
}

.overlay-content {
  overflow-y: scroll;
  max-height: 400px;
  padding: 80px;
  opacity: 1;
  background: white;
  border: 1px solid #cbcbcb;
  width: 40%;
}

.custom-type-messages {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.custom-type-messages-content {
  overflow-y: scroll;
  max-height: 400px;
  padding: 20px;
  opacity: 1;
  background: white;
  border: 1px solid #cbcbcb;
  width: 40%;
}

.custom-type-button {
  margin: 12px 0 0 12px;
}

.form-input {
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 18px;
  width: 100%;
  border-radius: 2px;
}

.form-button {
  margin-right: 4px;
}

.user-submit-button {
  background-color: #6210cc;
}

.create-channel-wrapper {
  align-items: baseline;
}

.channel-create-button {
  background-color: #62eeab;
  width: 100%;
}

.create-channel-checkbox {
  width: auto;
  margin: 0 5px 0 10px;
}

.select-custom-type {
  padding: 12px;
}

.select-custom-type select {
  width: 100%;
  font-size: 16px;
  padding-top: 12px;
  outline: none;
  border: none;
  cursor: pointer;
}

.send-message-button {
  border-radius: 0px 4px 4px 0px;
  margin-right: 8px;
}

.control-button {
  background: inherit;
  padding: 4px;
  margin-right: 1px;
}

.error {
  background: rgb(26, 22, 22);
  display: flex;
  padding: 24px;
  width: 100%;
}

.file-upload-label,
.user-profile-image-upload-label,
.number-of-undelivered-members-btn {
  cursor: pointer;
}

.file-upload-label img {
  width: 46px;
  vertical-align: middle;
}

.message-type-add {
  cursor: pointer;
  padding: 12px;
}

.message-type-label {
  margin: 0 10px;
}

.message-type-select {
  font-size: 16px;
  padding: 7px;
  outline: none;
  cursor: pointer;
}

.profile-image-fallback {
  width: 50px;
  background: #e9e9e9;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
}

.typing-indicator {
  position: fixed;
  bottom: 80px;
  background-color: #fff;
  opacity: 0.8;
  margin-left: 20px;
  margin-top: 20px;
  font-weight: 500;
}

.display-none {
  display: none;
}

.message-input-column {
  flex-direction: column;
}

.freeze-notification {
  position: sticky;
  top: 0;
  width: 100%;
  line-height: 30px;
  opacity: 70%;
  background-color: rgb(173, 201, 255);
  text-align: center;
}

.frozen-icon {
  width: 20px;
  margin-right: 10px;
}

.underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.user-copied-message {
  position: fixed;
  right: 20px;
}

.image-size-error {
  width: 100%;
  background: rgba(255, 0, 0, 0.2);
  padding: 5px;
  margin: 5px 0;
  text-align: center;
}

.channel-header-wrapper {
  display: flex;
  justify-content: space-between;
}

.create-metadata-conteiner {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 12px;
}

.create-metadata-title {
  margin: 0;
  padding: 5px;
  font-size: 16px;
}

.create-metadata-btn {
  padding: 5px 10px;
}

.metadata-modal-input {
  display: flex;
  margin: 0 0 10px;
}

.choise-report-obj {
  cursor: pointer;
  color: #6210cc;
  text-decoration: underline;
}

.number-of-undelivered-members {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}

.number-of-undelivered-message-btn:hover::after {
  content: attr(data-title);
  position: absolute;
  border: 1px solid gray;
  background-color: darkgray;
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 10px;
}

.banned-muted-users-list {
  margin-top: 20px;
}

.show-users-status-btn {
  display: block;
  margin-top: 5px;
}

.user-online-status {
  margin: 0 0 0 5px;
}
.user-profile-title {
  margin-top: 0;
}

.user-profile-image-wrapper,
.user-profile-nickname-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.user-profile-nickname-wrapper {
  margin: 0 0 10px;
}

.user-profile-image {
  border-radius: 50%;
  padding: 5px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.user-profile-nickname-input {
  width: auto;
}

.user-profile-nickname-button {
  border: none;
  background: transparent;
  color: black;
}

.user-profile-image-upload-label,
.user-profile-nickname-button {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  padding: 5px;
}

.number-of-undelivered-members {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}

.number-of-undelivered-message-btn:hover::after {
  content: attr(data-title);
  position: absolute;
  border: 1px solid gray;
  background-color: darkgray;
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 10px;
}

.do-not-disturb-wrapper,
.do-not-disturb-start-wrapper,
.do-not-disturb-end-wrapper,
.notifications-snooze-wrapper,
.notifications-snooze-input-wrapper {
  display: flex;
}

.do-not-disturb-wrapper,
.notifications-snooze-wrapper {
  flex-direction: column;
}

.do-not-disturb-wrapper,
.do-not-disturb-start-wrapper,
.do-not-disturb-end-wrapper,
.notifications-snooze-wrapper,
.notifications-snooze-input-wrapper {
  margin-bottom: 10px;
}

.notifications-overlay-content {
  padding: 40px;
  width: 50%;
  max-height: 500px;
}

.notification-modal-label,
.notification-modal-input {
  margin-right: 10px;
}

.notification-modal-input {
  width: 40%;
}

.open-poll-btn {
  padding: 5px;
  background: transparent;
  color: black;
  margin-left: 2px;
}

.create-poll-modal,
.create-poll-modal_inputs {
  display: flex;
  flex-direction: column;
}

.create-poll-modal {
  position: relative;
}

.create-poll-modal input {
  padding: 14px 32px 14px 6px;
}

.create-poll-modal_inputs {
  justify-content: space-between;
  margin-bottom: 10px;
}

.create-poll-modal .input_wrapper {
  margin-bottom: 5px;
}

.create-poll-modal .input_wrapper input[type='text'] {
  width: 60%;
}

.create-poll-modal h3 {
  text-align: center;
}

.option-add_btn {
  border: 1px solid #cbcbcb;
  border-radius: 0 4px 4px 0;
  font-size: 14px;
}

.poll-close_btn {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
}

.poll-create_btn {
  margin: 0 auto;
}

.poll-options {
  width: 100%;
}

.options_item {
  padding: 0 4px;
  margin-right: 4px;
  background: #cbcbcb;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.oc-members-list {
  margin-bottom: 80px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.scheduled-messages-list-modal {
  position: relative;
}

.close-button {
  background: none;
  padding: 0;
  position: absolute;
  top: 24px;
  right: 24px;
}

.update-input {
  display: flex;
}

.update-input input {
  width: 50%;
}

.scheduled-message-header {
  font-weight: 800;
  margin-bottom: 30px;
}

.schedule-message {
  margin-top: 20px;
}

.cancel-button {
  margin-right: 10px;
}

.scheduled-messages-settings-modal {
  z-index: 2;
}

.settings-header {
  margin-bottom: 30px;
  font-weight: 800;
}

.schedule-message-error {
  color: red;
  margin-bottom: 20px;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 1s;
  pointer-events: none;
  padding: 2px;
  background-color: #424242;
  border-radius: 4px;
  font-size: 10px;
  width: max-content;
  z-index: 100;
}

[data-tooltip]:hover::after {
  opacity: 1;
  top: -1.5em;
}

.option-icon {
  visibility: hidden;
  width: 14px;
  filter: brightness(0) invert(1);
}

.option_wrapper:hover .option-icon {
  visibility: visible;
}

.create-poll-modal .option input[type='text'] {
  width: 55%;
}

.add-new-option {
  background: white;
  border-radius: 4px;
  color: black;
  padding: 5px;
  font-size: 12px;
  margin-top: 10px;
}

.poll-message {
  position: relative;
}

.poll-status {
  position: absolute;
  bottom: 24px;
  right: 24px;
  font-size: 10px;
  padding: 5px;
}

.option_wrapper label,
.add-new-option {
  margin-right: 10px;
}

.poll-status_wrapper {
  width: 100%;
  min-height: 25px;
}

.vote {
  margin-right: 5px;
  padding: 5px;
  font-size: 10px;
  background: white;
  color: black;
  border-radius: 50%;
}

.pin-checkbox {
  display: inline-block;
}

.message-img {
  max-width: 100%;
}

#sendbird-emoji-list-portal {
  z-index: 100;
  position: absolute;
}
