html {
  background-color: #fbfbfb;
}

:root {
  /* --app-height: 100vh; */
}

/* body {
  margin: 0;
  padding: 0;
  width: 100%;
} */

html,
body {
  /* height: calc(100% - 60px); */
  margin: 0;
  padding: 0;
  /* height: 100%; */
  box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
  html,
  body {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

::-webkit-scrollbar {
  position: absolute;
  width: 0px;
}

.layout {
  /* width: 100%;
  height: 100vh; */
  /* overflow-y: scroll; */
  /* overflow: overlay; */
}

textarea:focus,
input:focus {
  outline: none;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  /* padding-left: 16px !important; */
}

.css-1nvnyqx-MuiPaper-root-MuiDrawer-paper {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.css-1nvnyqx-MuiPaper-root-MuiDrawer-paper {
  background-color: #ffffffd8 !important;
}

/* =[Loading]=============================================================================================== */
.gooey {
  top: 50%;
  left: 50%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
  background-color: rgba(229, 229, 229, 0.895);
  filter: contrast(20);
}

.dot {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 12px;
  left: 15px;
  filter: blur(4px);
  background-color: #000;
  border-radius: 50%;
  transform: translateX(0);
  animation: dot 2.8s infinite;
}

.dots {
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: dots 2.8s infinite;
}

.dots span {
  display: block;
  float: left;
  width: 12px;
  height: 12px;
  margin-left: 12px;
  filter: blur(4px);
  background: #000;
  border-radius: 50%;
}

@keyframes dot {
  50% {
    transform: translateX(96px);
  }
}

@keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

.invalid_page_container {
  text-align: center;
  padding-top: 40vh;
  min-height: 600px;
}

.default-background {
  position: absolute;
  z-index: 100;
  /* background: linear-gradient(175.2deg, #669ff4 0%, #b46feb 100%); */
  /* box-shadow: 0px 0px 37px -16px #000000; */
}

.center-all2 {
  display: flex;
  align-items: center;
  align-content: center;
}

.row {
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
}

.row.items > div {
  margin-right: 10px;
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute !important;
}

.absolute-vertical-center {
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute !important;
}

.small-button {
  padding: 5px;
  width: 30px;
  text-align: center;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  margin-right: 10px;
  line-height: 2;
  cursor: pointer;
}

.card-canvas {
  width: 100%;
  /* padding-bottom: 100%; */
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  margin-right: 20px;
}

.card-canvas.editor {
  max-width: 400px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin: auto;
  border: 1px solid #8e8e8e;
  aspect-ratio: 1 / 1;
}

.card-canvas-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.card-canvas-text-contents {
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
  padding: 5px;
  border-radius: 5px;
}

.card-canvas-text-contents-preview {
  text-align: center;
  vertical-align: middle;
  border: 1px dotted #c8c8c8;
  word-break: break-all;
  padding: 5px;
  border-radius: 5px;
}

.card-canvas-title-container {
  position: absolute;
  bottom: 0px;
  top: 30px;
  left: 0;
  right: 0;
  padding: 10px;
  width: auto;
  pointer-events: none;
}

.card-canvas-title {
  font-weight: 500;
  background: #1f439e;
  width: auto;
  color: #fff;
  display: inline-block;
  font-size: 16 px;
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
}

.card-canvas-title-link-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-canvas-title-link {
  width: 50px;
  height: 40px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: orange;
  bottom: 0;
  margin: auto;
}

[contenteditable='true']:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  color: rgb(24, 19, 19);
  display: block;
}

.color-boxes > div {
  width: 30px;
  height: 30px;
  margin: 5px;
  margin-top: 10px;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
  border-radius: 30px;
  cursor: pointer;
}

.round-button {
  width: 30px;
  height: 30px;
  margin: 5px;
  margin-top: 10px;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #fff;
  z-index: 100;
}

.slider {
  scroll-snap-type: x mandatory;
  display: flex;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  touch-action: pan-y;
}

.card-section {
  scroll-snap-align: start;
  text-align: center;
  position: relative;
  touch-action: pan-y;
}

.social-icons > img {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.input {
  border-radius: 8px;
  height: 40px;
  width: 100%;
  text-align: 'center';
  padding: 0 10px 0 10px;
}

.editor-input-container {
  border-radius: 5px;
  height: 60px;
  text-align: center;
}

.editor-input {
  width: 90%;
  height: 50px;
  padding: 5px;
  margin-right: 20px;
  border: 0;
  font-size: 20px;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
  border: none;
}

.button:disabled {
  background: #efefef !important;
  color: rgb(182, 182, 182) !important;
}

.button.default {
  color: #fff;
}

.button.invalid {
  background: #efefef;
  color: #fff;
}

.rounded-box {
  border-radius: 8px;
  border: 1px solid #dddddd;

  height: 50px;
  padding-left: 10px;
  display: table;
  width: calc(100% - 40px);
  cursor: pointer;
}

.rounded-box:hover {
  background: #efefef;
  color: '#000';
}

.reactEasyCrop_Container {
  background-color: #000;
  border: none;
}

.crop-container {
  position: relative;
  /* width: auto; */
  height: 300px;
}

.crop-inner {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.type-unselected {
  border: 2px solid transparent;
  padding: 5px;
}

.type-selected {
  border-radius: 10px;
  padding: 5px;
  margin: auto;
}

.link-container-text {
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word;
  min-height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex: 100%;
}

.link-container-video {
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word;
  min-height: 50px;
  justify-content: space-between;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
  overflow: hidden;
}

/* new */
.card-container {
  /* overflow: hidden; */
  margin-bottom: 15px;
}

.card-container.small {
  height: 60px;
  background: #fff;
}

.card-container.small {
  height: 60px;
}

.center-all {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
}

.youtube-frame {
  width: 100%;
  border: 0;
  position: relative;
}

.simple-a-link {
  text-decoration: none;
}

/* Simulator container child */
.admin-slider-child {
  scroll-snap-align: start;
  text-align: center;
  position: relative;
  min-width: 100%;
  height: calc(100vh - 80px);
}

.swiper {
  /* 100%가 필요한 경우 각 swiper객제에서 인라인으로 지정할것 */
  /* width: 100%; */
  z-index: 0 !important;
}

.swiper-wrapper {
}

.feedlink-component .swiper-pagination {
  position: absolute;
  bottom: 150px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  height: auto;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.swiper-pagination {
  /* bottom: 5px;
  position: relative; */
}

.swiper-button-prev,
.swiper-button-next {
  color: white;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.custom-swiper > .swiper-button-prev,
.custom-swiper > .swiper-button-next {
  position: absolute;
  top: 0;
  rotate: 90deg;
  left: 0;
  width: 100%;
  margin: 0 auto;
}

.custom-swiper > .swiper-button-prev {
  top: -5px;
}

.custom-swiper > .swiper-button-next {
  top: 410px;
}

.link-title {
  font-size: 18px;
}

.div-round {
  overflow: hidden;
  position: relative;
  z-index: 10;
  /* -webkit-border-radius: 20px;
  border-radius: 20px; */
}

.div-round::before {
  display: block;
  content: '';
}

.iframe-round {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 0;
  -webkit-border-radius: 20px;
  border-radius: 10px;
}

/* Common here : 여기서부터 새로운 버전 */

.input {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 5px;
  vertical-align: middle;
  word-break: break-all;
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  border: none;
}

/* bottom sheet  */

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 4;
  /* margin: auto; */
  max-width: 550px;
  width: 100%;
  margin: auto;
  left: 0 !important;
}

/* calendar */
.css-169iwlq-MuiCalendarPicker-root,
.css-1vcokmn-MuiCalendarOrClockPicker-root,
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
  background-color: #fcfcfc !important;
}

.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #11c668 !important;
}

.css-qklzlb-MuiDayPicker-header {
  background-color: #f5f5f5;
  border-radius: 20px;
  width: 90%;
  margin: 0 auto 10px;
}

.css-qklzlb-MuiDayPicker-header > span {
  color: black;
}

.css-qklzlb-MuiDayPicker-header > span:first-child {
  color: #b04242;
}

/* 링크 토글버튼 */
.css-priqlz-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #11c668 !important;
}

.css-priqlz-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #11c668 !important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled {
  text-decoration: line-through;
}

/* 이용자정보 textfield 클릭시*/
.userInfo
  > div
  > div
  > .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.userInfo
  > div
  > .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.userInfo
  > div
  > div
  > .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.userInfo
  > div
  > .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #11c668 !important;
  color: #11c668 !important;
}

input:-internal-autofill-selected,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 9999s ease-out;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

/* 이용자정보 textfield hover시 */
.userInfo
  > div
  > div
  > .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.userInfo
  > div
  > .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.userInfo
  > div
  > .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #11c668;
}

/* 이용자정보 textfield 텍스트 */
.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #11c668 !important;
  background-color: white !important;
}

.userInfo > div > .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root > span,
.userInfo > div > div > .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root > span {
  display: none;
}

.userInfo > div > .css-l76kzw-MuiFormControl-root-MuiTextField-root {
  margin: 0;
}

.userInfo > .css-l76kzw-MuiFormControl-root-MuiTextField-root {
  margin: 0;
}

.userInfo > div:nth-child(3) > div:first-child {
  margin-right: 10px;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #11c668 !important;
}

.userInfo > div > div > .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.userInfo > div > div > div > .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid #eeeeee;
}

/* Gnb 메뉴 */
.gnb-menu > div:nth-child(3) > ul > .css-h4y409-MuiList-root {
  background-color: #11c668;
  color: white;
  border-radius: 15px;
}

.gnb-menu > div:nth-child(3) > ul > .css-h4y409-MuiList-root > li {
  justify-content: flex-start;
}

.gnb-menu > div:nth-child(3) > ul > .css-h4y409-MuiList-root > li:hover {
  background-color: transparent;
}

/* 예약 상품 선택 selectBox */
.item-select > fieldset {
  border-color: #eeeeee !important;
  border-width: 1px !important;
}

.item-select > svg {
  fill: #cbcbcb;
}

/** 텍스트필드 마진 제거 */
.css-l76kzw-MuiFormControl-root-MuiTextField-root {
  margin: 0 !important;
}

.react-colorful {
  width: 100% !important;
}

/* CKEditor */
figure {
  margin: 0;
}

figure img {
  width: 100%;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}

.editor-area > p > img {
  width: 100%;
}

[contenteditable] {
  outline: 0px solid transparent;
}

.color {
  width: 32px;
  flex-shrink: 0;
  cursor: pointer;
}

.color,
/* .preview {
  height: 32px;
  border-radius: 8px;
  box-shadow: inset 0 0 1px #888;
} */

.color--0 {
  order: -1;
  border: 3px solid transparent;
}

.color--active {
  border: 3px solid #88c;
}

/* .preview {
  flex-grow: 1;
  margin: 0 8px;
} */

.block-spacer {
  background: red;
  /* position: relative; */
  width: 0px !important;
  width: 0px;
  text-align: center;
  transition: width 0.5s ease;
}

.block-spacer.on {
  transition: width 0.5s ease;
  /* left: 40px; */
  width: 0px;
}

.block-spacer .blocks {
  /* position: relative; */
  opacity: 1;
  background: #fff;
  border-radius: 30px;
  width: 150px;
  padding: 2px 20px;
  margin-left: 10px;
}

.block-spacer .blocks.on {
  width: 100px;
  opacity: 1;
}

.block-spacer .blocks .icons {
  width: 10px;
}

.block-spacer .blocks.on .icons {
  width: 60px;
}

.block-spacer .buttons {
  transform: rotate(0deg);
  transition: transform 0.5s ease;
}

.block-spacer .buttons.on {
  transform: rotate(-45deg);
  transition: transform 0.5s ease;
}

/*  */
.item-title {
  font-weight: 500;
  font-size: 16px;
}

.sub-title {
  color: #666;
  font-size: 14px;
}

.oml-20 {
  margin-left: 20px;
}

.omt-20 {
  margin-top: 20px;
}

.opd-20 {
  padding: 20px;
}

.fw-700 {
  font-weight: 700;
}

.Toastify__toast {
  border: 1px solid #0bbd58 !important;
  border-radius: 10px !important;
  font-weight: 700;
}

.ReactModal__Content--after-open {
  @media (max-width: 590px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.ohmoss-masonry-container {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  /* Your container needs a fixed height, and it 
   * needs to be taller than your tallest column. */
  height: calc(100vh - 103px);

  /* Optional */
  border-radius: 3px;
  width: 100%;
  margin: 0px auto;
  counter-reset: items;
}

.ohmoss-masonry-item {
  width: 33.3%;
  /* Optional */
  position: relative;
  padding: 0 10px;
  box-sizing: border-box;
  height: auto;
}

/* Just to print out numbers */
/* div.ohmoss-masonry-item::before {
  counter-increment: items;
  content: counter(items);
} */

/* Re-order items into 3 rows */
.ohmoss-masonry-item:nth-of-type(4n + 1) {
  order: 1;
}
.ohmoss-masonry-item:nth-of-type(4n + 2) {
  order: 2;
}
.ohmoss-masonry-item:nth-of-type(4n + 3) {
  order: 3;
}
.ohmoss-masonry-item:nth-of-type(4n) {
  order: 4;
}

/* Force new columns */
.break {
  flex-basis: 100%;
  width: 0;
  margin: 0;
  content: '';
  padding: 0;
}

#subMenuContainer.on {
  position: fixed;
  top: 100px;
}
