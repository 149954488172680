/* PC (해상도 1024px)*/
/* @media all and (min-width: 1079px) { */
@media all and (min-width: 640px) {
  /* ===[MainLayouy]================================================================================= */
  .main_container_wrapper {
    align-items: center;
    /* display: flex; */
    /* padding-left: 12.125rem;
    padding-right: 12.125rem; */
    /* padding-left: 8.125rem;
    padding-right: 8.125rem; */

    /* padding-top: 80px; */
    height: 100%;
    /* max-width: 1200px; */
    /* min-width: 1000px; */
    /* position: relative; */
    margin-left: auto;
    margin-right: auto;
    /* top: 60px; <-여기에서 사용하지 말고 각 페이지에서 선언해서 사용할것. */
  }

  .main_container {
    width: 100%;
    padding-top: 32px;
  }

  .mobile_only {
    display: none;
  }

  .full_width {
    width: 100vw !important;
    margin-left: calc(-50vw + 50%);
  }

  .full_height {
    height: 100vh !important;
    min-height: 600px;
    /* margin-top: calc(-50vw + 50%); */
  }

  .full_height_without_gnb {
    height: calc(100vh - 80px) !important;
    /* margin-top: calc(-50vw + 50%); */
  }

  .half {
    width: 50%;
    height: 100%;
  }

  /* ===[MAIN]================================================================================= */
  .main_section {
    width: 100%;
    min-height: 1040px;
    display: flex;
    min-width: 1040px;

    /* height: 600px; */
  }

  .home_background_image {
    background-size: cover;
    /* width: 100%; */
    background-image: url('/public/image/homeBackGround.png');
  }

  .main_left {
    display: inline-grid;
    justify-content: flex-end;
    min-width: 728px;
    /* padding-right: 64px; */
  }
  .main_rigth {
    display: inline-grid;
    min-width: 600px;

    /* padding-left: 64px; */

    /* justify-content: flex-end; */
  }

  .main_title_container {
    height: fit-content;
    padding-top: 24vh;
    /* padding-top: 24%; */
  }

  .main_one_click {
    padding-right: 120px;
    padding-top: 280px;
  }

  .main_title_sub {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .main_phone_img {
    padding-top: 8vh;
    min-height: 500px;
    max-height: 740px;
  }

  .main_phone_img_top {
    padding-top: 160px;
  }

  .main_overlap_img_sns_1 {
    position: absolute;
    left: 247px;
    top: -482px;
  }
  .main_overlap_img_sns_2 {
    position: absolute;
    left: 321px;
    top: -380px;
  }
  .main_overlap_img_sns_3 {
    position: absolute;
    left: 396px;
    top: -240px;
  }
  .main_overlap_img_sns_4 {
    position: absolute;
    left: 340px;
    top: -86px;
  }

  .main_overlap_img_phone {
    position: absolute;
    left: -480px;
    height: 740px;
  }
  .main_overlap_img_icons {
    position: absolute;
    padding-top: 186px;
    left: -590px;
    height: 360px;
  }

  .main_overlap_img_map {
    position: absolute;
    left: 346px;
    top: -54px;
    z-index: -1;
  }
  .main_overlap_img_call {
    position: absolute;
    left: -196px;
    padding-top: 464px;
    z-index: -1;
  }

  .main_title_influencer {
    font-size: 88px;
    color: white;
    font-weight: 100;
    width: 100%;
    padding-top: 140px;
  }

  .main_title_influencer_sub {
    color: rgba(255, 255, 255, 0.599);
  }

  .main_title_influencer_container {
    padding-top: 64px;
    justify-content: center;
    height: fit-content;
    width: 1160px;
    padding-left: calc(50% - 580px);
    overflow-x: scroll;

    /* display: flex; */
  }

  .main_title_influencer_img_container {
    /* transition: 0.9s; */

    /* padding: 0;
    margin: 0;
    width: 100%; */
    /* background-size: cover; */
    object-fit: cover;
    object-position: center;
    width: 200px;
    height: 200px;
    border-radius: 110px;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 16px;

    /* padding-top: 64px; */
  }

  .main_title_influencer_img {
  }

  .main_title_influencer_name {
    position: absolute;
    color: white;
    z-index: 2;
    /* margin-left: 60px; */
    text-align: center;
    width: 200px;
    font-size: 20px;
    margin-top: 72px;
  }
  .main_title_influencer_type {
    width: 100%;
    position: relative;
    text-align: center;
  }

  .main_title_influencer_type_text {
    position: absolute;
    margin-top: 124px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #ffffff99;

    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 40px;
  }

  .main_overlap_img_crown {
    position: absolute;
    margin-left: 106px;
    /* left: calc(50% + 6px); */
    /* margin-left: calc(50% + 6px); */
    margin-top: -25px;
  }
  .main_overlap_img_curve {
    position: absolute;
    margin-top: 166px;
  }

  .main_title_url {
    display: inline-block;
    width: 466px;
    height: 60px;
    line-height: 60px;
    border: 1px solid white;
    border-radius: 60px;
    text-align: left;
    margin-top: 80px;
  }

  .main_title_url_logo_box {
    padding-left: 36px;
    padding-right: 118px;
    padding-top: 19px;
    padding-bottom: 17px;
    width: 154px;
    border-radius: 60px;
    background-color: white;
    border: 1px solid white;
  }

  .main_title_url_logo {
    height: 28px;
    position: absolute;
    padding-top: 15px;
  }
  /* ===[FOOTER]================================================================================= */
  .footer {
    height: 312px;
    background-color: #373737;
  }

  .footer_logo {
    height: 50px;
    padding-top: 80px;
  }

  .footer_des {
    padding-top: 16px;
    font-size: 14px;

    color: white;
    opacity: 0.7;
  }

  /* ===[FONT]================================================================================= */
  .font_title_eng {
    font-size: 120px;
  }
  .font_title_kor {
    font-size: 80px;
  }

  /* ===[GNB]================================================================================= */
  .gnb_container_wrapper {
    position: fixed;
    top: 0px;
    z-index: 10;
    background-color: white;
    width: calc(100% - 2.125rem * 2);

    height: 80px;
    display: flex;
    padding-left: 2.125rem;
    padding-right: 2.125rem;
    align-items: center;
  }

  .gnb_container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .gnb_logo_container {
    float: left;
  }

  .gnb_logo {
    /* width: 100px; */
    height: 36px;
    /* padding-top: 8px; */
    /* height: 60px;
    line-height: 60px; */
  }

  .gnb_more_menu {
    visibility: hidden;
  }

  .gnb_menu_container {
    display: flex;
    float: right;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
  }

  .gnb_menu {
    padding-left: 16px;
    font-size: 14px;
  }

  .gnb_clicked_menu {
    border-bottom: 2px solid white;
    padding-bottom: 6px;
  }

  .login-page-container {
    margin: auto;
    width: 500px;
  }

  .page.create-link-page {
    padding: 0 20px 20px 20px;
  }
}
