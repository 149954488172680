/* kosugi-maru-regular - latin_japanese */
@font-face {
  font-family: 'Kosugi Maru';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/japanese/kosugi-maru-v14-latin_japanese-regular.ttf');
}

/* @font-face {
  font-family: 'Kumbh Sans';
  font-style: extra-bold;
  font-weight: 600;
  src: url('fonts/english/KumbhSans-ExtraBold.ttf');
} */

@font-face {
  font-family: 'Noto Sans';
  src: url('fonts/english/NotoSans-Regular.ttf');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/NotoSansKR-Thin.woff) format('woff');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/NotoSansKR-Light.woff) format('woff');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NotoSansKR-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/NotoSansKR-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/NotoSansKR-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/NotoSansKR-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/NotoSansKR-Black.woff) format('woff');
}

:lang(jp) {
  font-family: 'Noto Sans ', 'Kosugi Maru', sans-serif;
}

:lang(ko) {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
}

:lang(en) {
  font-family: 'Red Hat Display', sans-serif;
}
@media (max-width: 900px) {
  :lang(ko) {
    font-size: 16px;
  }
}

.MuiTab-root {
  font-family: 'Noto Sans KR' !important;
}

/* ===[Font]================================================================= */
.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font24 {
  font-size: 24px;
}

.font32 {
  font-size: 32px;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.skiny {
  font-weight: 100;
}

.underline {
  text-decoration: 'underline';
}

/* ===[Color]================================================================= */

.blue {
  color: #3e64ea;
}

.gray {
  color: rgba(0, 0, 0, 0.5);
}

.light_gray {
  color: rgb(187, 187, 187);
}

.white {
  color: white;
}

.black {
  color: black;
}

.black_back {
  background-color: black;
}

.white_back {
  background-color: white;
}

/* ===[position]================================================================= */

/* .full_width {
  position: absolute;
  width: 100vw;
  left: calc(-50vw + 50%);
} */
.overlap_img {
}

.height100 {
  height: 100%;
}

.items_center {
  align-items: center;
}

.center {
  text-align: center;
}

.left {
  float: left;
}

.right {
  float: right;
  /* display: inline-block; */
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.full_screen {
  /* z-index: 101; */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.full_screen::before {
  content: '';
  /* opacity: 0.85; */
  box-shadow: rgb(0, 0, 0) 0 0 0 9999px;
  /* z-index: 1000; */
}

.padding_bottom_4 {
  padding-bottom: 4px;
}

.padding_bottom_8 {
  padding-bottom: 8px;
}

.padding_bottom_12 {
  padding-bottom: 12px;
}

.padding_bottom_16 {
  padding-bottom: 16px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding_bottom_24 {
  padding-bottom: 24px;
}

.padding_bottom_28 {
  padding-bottom: 28px;
}

.padding_bottom_32 {
  padding-bottom: 32px;
}

.padding_top_4 {
  padding-top: 4px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding_top_80 {
  padding-top: 80px;
}

/* ===[]================================================================= */

.height_24 {
  height: 24px;
}

.height_32 {
  height: 32px;
}

.height_40 {
  height: 40px;
}

.height_48 {
  height: 48px;
}

.height_62 {
  height: 62px;
}

/* ===[]================================================================= */
.btn {
  cursor: pointer;
}

.btn:hover {
  opacity: 0.5;
}

.btn_up {
  cursor: pointer;
  top: 0;
  transition: all 0.3s ease-in-out;
}

.btn_up:hover {
  top: -5px;
  position: relative;
}

/* do not remove */
.global-width {
  max-width: 550px;
  /* padding: 0 20px; */
  width: 100%;
  z-index: unset;
}

.ck-content {
  height: 400px;
}

/* .ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
} */

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.css-cwhad8-MuiDateCalendar-root {
  /* width: 600px !important;
  height: 600px !important; */
}

.css-3dah0e-MuiModal-root-MuiDialog-root {
  z-index: 100000 !important;
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  z-index: 100000000 !important;
}

.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root {
  /* min-width: 60px !important;
  min-height: 60px !important; */
}

/* Full calendar */
.fc .fc-daygrid-event {
  overflow: hidden;
}

.fc .fc-toolbar-title {
  font-size: 18px;
}

::-webkit-scrollbar {
  display: none;
}

.MuiTypography-root {
  text-align: center;
}

.snap-container {
  width: 100vw;
  /* viewport width */
  height: 100vh;
  /* viewport height */
  overflow-x: scroll;
  /* enable horizontal scrolling */
  scroll-snap-type: x mandatory;
  /* enforce horizontal scroll snapping */
  display: flex;
  /* align sections horizontally */
}

.snap-section {
  width: 100vw;
  /* viewport width */
  scroll-snap-align: start;
  /* snap to the start of the section */
  /* add more styling as needed */
}

.MuiMenu-paper.MuiPopover-paper {
  max-height: 150px;
}

.fit-swiper {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow-y: auto;
}

.ReactModal__Body--open {
  overflow: hidden !important;
}

.ReactModal__Overlay--after-open {
  /* z-index: 3 !important; */
}

.hash-tag {
  display: inline-block;
  padding: 3px;
  border: 1px solid red;
}

.chip-on {
  border: 1px solid red !important;
  animation: vibration 0.1s;
  animation-iteration-count: 2;
}

@keyframes vibration {
  from {
    transform: rotate(5deg);
  }

  to {
    transform: rotate(-5deg);
  }
}

.common-typo-title {
  line-height: 18px;
  white-space: pre-line;
}

.common-typo-title.subject {
  font-weight: 700;
  color: #000;
  font-size: 14px;
}

.common-typo-title.content {
  font-weight: 400;
  color: #808080;
  word-wrap: break-word;
  font-size: 13px;
}

.common-hash-tags {
  color: var(--blue-500, #638dff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.ck-editor__editable {
  min-height: 100px;
  max-height: 300px;
}

.MuiTabs-flexContainer {
  /* justify-content: space-between; */
}

.universal-layout {
  max-width: 550px;
  width: 100%;
  margin: auto;

  /* transition: all 0.5s ease-in-out; */

  /* height: 100vh; */
  /* height: calc(100vh - 44px); */
  /* background: #fff */
}

.management-layout {
  /* transition: all 0.5s ease-in-out; */
}

.universal-layout.preview {
  background: transparent !important;
  /* transition: all 0.1s ease-in-out; */
}

.management-layout.preview {
  background: transparent !important;
  /* transition: all 0.1s ease-in-out; */
}

.management-layout {
  /* transition: all 0.5s ease-in-out; */
}

.global-width {
  max-width: 550px;
}

.MuiTabPanel-root {
  background: #fff;
}

.admin-gnb {
  height: 80px;
  background: #fff;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.admin-gnb.hide {
  height: 0;
  opacity: 0;
  transition: height 0.3s ease-in-out;
}

.modal-header.hide {
  height: 0;
  opacity: 0;
  transition: height 0.5s ease-in-out;
}

.block-selector {
  bottom: 0px;
  transition: bottom 0.5s ease-in-out;
}

.block-selector.hide {
  bottom: -100px;
  transition: bottom 0.5s ease-in-out;
}

.sticker-item {
  font-size: 11px !important;
}
